import React, { useEffect } from "react";

import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "components/layout";

// import Home from "sections/home/Home.js";
// import About from "sections/about/About.js";
// import Services from "sections/services/Services.js";
import LandingApigateway from "sections/service-apigateway/Apigateway.js";
// import Partners from "sections/partners/Partners.js";
// import Testimonials from "sections/testimonials/Testimonials.js";
// import Technologies from "sections/technologies/Technologies.js";
// import TeamOne from "sections/team/TeamOne.js";
import Contact from "sections/contact/Contact.js";

import "../lang/i18n";

import { Provider, useDispatch } from "react-redux";
import { store } from "../redux/store";
import { setCurrentPage } from "../redux/slices/page";

const Apigateway = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage("landing"));
  }, [dispatch]);

  const { site } = props.data;

  return (
    <div>
      <Helmet>
        <title>{site.meta.title}</title>
        <meta name="title" content={site.meta.title} />
        {/* <meta name="description" content={site.meta.description} /> */}
        <meta name="keywords" content={site.meta.keywords}></meta>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sistematica.io/" />
        <meta property="og:title" content={site.meta.title} />
        <meta property="og:description" content={site.meta.description} />
        <meta property="og:image" content="img/preview.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://sistematica.io/" />
        <meta property="twitter:title" content={site.meta.title} />
        <meta property="twitter:description" content={site.meta.description} />
        <meta property="twitter:image" content="img/preview.png" />
      </Helmet>
      <Provider store={store}>
        <Layout
          isHome={true}
          sections={[
            "home",
            "about",
            "services",
            "partners",
            /* "technologies",
            "team", */
            "solutions",
            "contact",
            "account",
            "language",
          ]}
        >
          {/* <Home />
          <About />
          <Services />
          <Partners />
          <TeamOne />
          <Technologies />
          <Testimonials /> */}
          <LandingApigateway />
          <Contact />
        </Layout>
      </Provider>
    </div>
  );
};

export default Apigateway;

export const myQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        keywords
      }
    }
  }
`;
