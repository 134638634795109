import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import AnimationContainer from "components/animation-container";
// import AnimatedHeading from "components/animated-heading";

import { Trans, withTranslation } from "react-i18next";

class Apigateway extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      half: 0,
    };
  }

  updateDimensions = () => {
    if (this.state.height !== window.innerHeight) {
      this.setState({ height: window.innerHeight });
    }
    if (this.state.width !== window.innerWidth) {
      this.setState({
        width: window.innerWidth,
        half: Math.round((window.innerWidth * 0.3) / 2),
      });
    }
  };

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
      half: Math.round((window.innerWidth * 0.3) / 2),
    });
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { t } = this.props;

    const Section = styled.section`
      position: relative;
      padding-top: 100px;
      padding-bottom: 200px;
    `;

    const Overlay = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(07, 28, 43, 0);
      background-image: url(${this.props.main.childImageSharp.fluid.src});
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: bottom right;
    `;

    const Title = styled.h2`
      font-size: 50px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      @media (min-width: 769px) and (max-width: 1500px) {
        font-size: 35px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
      }
    `;

    const SubText = styled.h1`
      font-size: 45px;
      font-family: Teko;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (min-width: 769px) and (max-width: 1500px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        margin-left: 2px;
        font-size: 15px;
        line-height: 15px;
      }
    `;

    const Color = styled.span`
      color: #f29100;
      font-size: 50px;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
      }
      @media (min-width: 769px) and (max-width: 1500px) {
        font-size: 35px;
      }
    `;

    const Content = styled.div`
      max-wid
      z-index: 10;
    `;

    const MidContent = styled.div`
      height: 400px;
      background-color: #2ba0ea;
      border-radius: 25px;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 5%;
      bottom: -200px;
      z-index: 2;
      width: 100%;
      @media (min-width: 1024px) and (max-width: 1400px) {
        right: 5%;
        bottom: -200px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        right: 5%;
        bottom: -200px;
      }
      @media (max-width: 767px) {
        right: 5%;
        bottom: -200px;
      }
    `;

    const MidImageContainer = styled.img`
      width: 30%;
      border-radius: 50%;
      position: absolute;
      left: 6.25%;
      z-index: 2;
    `;

    const MidTextContainer = styled.div`
      width: 60%;
      margin: 0 2.5% 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `;

    const MidContentText = styled.p`
      margin: 11px 0 0 0;
      font-size: 14px;
      font-weight: 300;
      color: #fff;
      @media (min-width: 1440px) {
        font-size: 20px;
      }
      @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
      }
    `;

    const BenefitSection = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #1C3258;
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `;

    const BenefitContainer = styled.div`
      text-align: center;
      background-color: #1c3258;
      padding-top: 250px;
      padding-bottom: 8px;
    `;

    const BenefitElement = styled.div`
      margin-bottom: 20px;
      text-align: center;
      padding: 40px;
      border-radius: 20px;
      transition: 0.2s;
      &:hover {
        background-color: #071c2b;
        box-shadow: 0 28px 60px rgb(43, 160, 234, 0.2);
        transform: translateY(-10px);
      }
    `;

    const BenefitIcon = styled.div`
      margin-bottom: 20px;
      img {
        max-height: 70px;
      }
    `;

    const BenefitText = styled.p`
      margin: 11px 0 0 0;
      font-size: 14px;
      font-weight: 300;
      color: #c5c5c5;
      @media (min-width: 961px) {
        font-size: 16px;
      }
    `;

    return (
      <>
        <Section id="apigateway">
          <Overlay
            style={{
              height: `${this.state.width / 2}px`,
            }}
          >
            <Container style={{ maxWidth: "90%" }}>
              <Content style={{ maxWidth: "60%" }}>
                <Title>
                  <Trans i18nKey={"apigateway.title"}>
                    <Color>Amazon</Color> API Gateway
                  </Trans>
                </Title>
                <SubText>{t("apigateway.subText")}</SubText>
              </Content>
            </Container>
          </Overlay>
          <MidContent>
            <MidImageContainer
              style={{
                top: `-${Math.round((this.state.width * 0.3) / 2)}px`,
              }}
              src={this.props.circle.childImageSharp.fluid.src}
              alt="Circle Img"
            />
            <MidTextContainer>
              <MidContentText>{t("apigateway.paragraph1")}</MidContentText>
              <MidContentText>{t("apigateway.paragraph2")}</MidContentText>
            </MidTextContainer>
          </MidContent>
        </Section>
        <BenefitSection>
          <BenefitContainer>
            <Container>
              <Title>{t("apigateway.subtitle")}</Title>
              <Row className="justify-content-md-center">
                <Col md={4}>
                  <AnimationContainer animation="fadeInLeft" delay={150}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit1.childImageSharp.fluid.src}
                          alt="Cloud Computing"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit1")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
                <Col md={4}>
                  <AnimationContainer animation="fadeInDown" delay={300}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit2.childImageSharp.fluid.src}
                          alt="CRM"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit2")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
                <Col md={4}>
                  <AnimationContainer animation="fadeInRight" delay={450}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit3.childImageSharp.fluid.src}
                          alt="Marketing"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit3")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
                <Col md={4}>
                  <AnimationContainer animation="fadeInLeft" delay={600}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit4.childImageSharp.fluid.src}
                          alt="Robot"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit4")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
                <Col md={4}>
                  <AnimationContainer animation="fadeInUp" delay={750}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit5.childImageSharp.fluid.src}
                          alt="Datos"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit5")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
                <Col md={4}>
                  <AnimationContainer animation="fadeInRight" delay={900}>
                    <BenefitElement>
                      <BenefitIcon>
                        <img
                          src={this.props.benefit6.childImageSharp.fluid.src}
                          alt="Aplicaciones"
                        />
                      </BenefitIcon>
                      <BenefitText>{t("apigateway.benefit6")}</BenefitText>
                    </BenefitElement>
                  </AnimationContainer>
                </Col>
              </Row>
            </Container>
          </BenefitContainer>
        </BenefitSection>
      </>
    );
  }
}

export default withTranslation()((props) => (
  <StaticQuery
    query={graphql`
      query {
        main: file(relativePath: { eq: "apigateway-main.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              src
            }
          }
        }
        circle: file(relativePath: { eq: "apigateway-circle.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              src
            }
          }
        }
        benefit1: file(relativePath: { eq: "icons/AGW-benefit1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        benefit2: file(relativePath: { eq: "icons/AGW-benefit2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        benefit3: file(relativePath: { eq: "icons/AGW-benefit3.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        benefit4: file(relativePath: { eq: "icons/AGW-benefit4.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        benefit5: file(relativePath: { eq: "icons/AGW-benefit5.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        benefit6: file(relativePath: { eq: "icons/AGW-benefit6.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({
      main,
      circle,
      benefit1,
      benefit2,
      benefit3,
      benefit4,
      benefit5,
      benefit6,
    }) => (
      <Apigateway
        main={main}
        circle={circle}
        benefit1={benefit1}
        benefit2={benefit2}
        benefit3={benefit3}
        benefit4={benefit4}
        benefit5={benefit5}
        benefit6={benefit6}
        {...props}
      />
    )}
  />
));
